<template>
  <Sidebar ref="sidebar" :title="$tuf('website_settings')">
    <form @submit.prevent="save" class="pb-20">

      <Overlay v-if="loading" class="z-2 mt-16 flex justify-center items-center bg-white">
        <div class="bg-white shadow rounded w-8 h-8 flex justify-center items-center text-prasset-green-500">
          <LoadingIndicator />
        </div>
      </Overlay>

      <div class="relative z-1 grid grid-cols-6 gap-6" v-if="ready">
        <div class="col-span-6">
          <label for="input_name" class="text-sm text-prasset-green-800 mb-2 block">
            {{ $tuf('name') }}
          </label>
          <TextField id="input_name" name="name" v-model="form.name" :error="errors.name" />
        </div>

        <div class="col-span-6">
          <label for="input_theme" class="text-sm text-prasset-green-800 mb-2 block">
            {{ $tuf('theme') }}
          </label>
          <SelectField id="input_theme" name="theme"  v-model="form.theme_options.skin"
            :error="errors.theme"
            :optional="false"
            :options="{
              basis: 'Basis',
              modern: 'Modern',
              klassiek: 'Klassiek',
              frivool: 'Frivool',
            }"
          />
        </div>

        <VariantConfigurator class="col-span-6" v-model="form.theme_options.colors" />

        <div class="col-span-3">
          <label for="logo-light" class="text-sm text-prasset-green-800 mb-2 block">
            {{ $tuf('light_logo') }}
          </label>

          <FilesField
            name="logo-light"
            :entryId="website_id"
            entryType="website"
            collection="website"
            :config="{ maxFiles: 1 }"
            :existingFiles="getMediaCollection(form.theme_options.logo.light)"
            :modelValue="form.theme_options.logo.light"
            @change="onMediaChanged('light', $event)"
          />
        </div>
        <div class="col-span-3">
          <label for="logo-dark" class="text-sm text-prasset-green-800 mb-2 block">
            {{ $tuf('dark_logo') }}
          </label>

          <FilesField
            name="logo-dark"
            :entryId="website_id"
            entryType="website"
            collection="website"
            :config="{ maxFiles: 1 }"
            :existingFiles="getMediaCollection(form.theme_options.logo.dark)"
            :modelValue="form.theme_options.logo.dark"
            @change="onMediaChanged('dark', $event)"
          />
        </div>
      </div>

      <div class="w-full bg-white border-t border-gray-300 absolute bottom-0 inset-x-0 px-5 py-4 z-20">
        <div class="flex">
          <button class="button button--outlined mr-4" type="button" @click="$refs.sidebar.close()">
            {{ $tuf('cancel') }}
          </button>

          <button
            type="button"
            class="button button--outlined relative mr-4"
            :class="{ 'opacity-25': loading && submittedBy !== 'save' }"
            :disabled="loading"
            @click="save(false, 'save')"
          >
            <span :class="{ 'invisible': loading && submittedBy === 'save' }">
              {{ $tuf('save') }}
            </span>
            <span v-if="loading && submittedBy === 'save'" class="absolute inset-0 flex justify-center items-center">
              <LoadingIndicator />
            </span>
          </button>

          <button
            type="submit"
            class="button button--opague relative"
            :class="{ 'opacity-25': loading && submittedBy !== 'save-and-back' }"
            :disabled="loading"
          >
            <span :class="{ 'invisible': loading && submittedBy === 'save-and-back' }">
              {{ $tuf('save_and_back') }}
            </span>
            <span v-if="loading && submittedBy === 'save-and-back'" class="absolute inset-0 flex justify-center items-center">
              <LoadingIndicator />
            </span>
          </button>
        </div>
      </div>
    </form>
  </Sidebar>
</template>

<script>
import { toRefs, reactive, onMounted } from '@vue/composition-api';
import FilesField from '@/components/field/Files';
import LoadingIndicator from '@/components/LoadingIndicator';
import Overlay from '@/components/Overlay';
import SelectField from '@/components/field/Select';
import Sidebar from '@/modules/core/views/components/Sidebar';
import TextField from '@/components/field/Text';
import useWebsite from '@/compositions/useWebsite';
import VariantConfigurator from '@/modules/core/views/components/VariantConfigurator';
import { colors } from '@/compositions/useColorPicker';
import useChangedState from '@/compositions/useChangedState';

export default {
  name: 'WebsiteEdit',

  components: {
    FilesField,
    LoadingIndicator,
    Overlay,
    SelectField,
    Sidebar,
    TextField,
    VariantConfigurator,
  },

  setup(props, { refs, root }) {
    const { project_id, website_id, page_id } = root.$route.params;
    const { updateWebsite, fetchWebsite, reloadWebsite, reloadPage, fetchThemes } = useWebsite();
    const { changed, watchChanges, commitChanges } = useChangedState();

    const state = reactive({
      form: {
        theme: 'default',
        theme_options: {
          skin: 'Basis',
          colors: {},
          logo: {
            light: [],
            dark: [],
          },
        },
      },
      themes: {},
      media: [],
      errors: {},
      ready: false,
      changed,
      loading: false,
      submittedBy: null,
    });

    /**
     * Load form data.
     */
    async function load() {
      state.loading = true;

      // Load available themes
      state.themes = await fetchThemes(project_id);

      const data = await fetchWebsite(project_id, website_id);

      //tempfix: when there isn't a logo fied available;
      if (! data.theme_options) {
        data.theme_options = {};
      }

      if (data.theme_options && !data.theme_options.logo) {
        data.theme_options.logo = { light: [], dark: [] };
      }

      if (data.theme_options && !data.theme_options.colors) {
        data.theme_options.colors = colors;
      }

      if (data.theme_options && !data.theme_options.skin) {
        data.theme_options.skin = 'Basis';
      }

      state.form = {
        description: data.description,
        name: data.name,
        theme_options: data.theme_options,
        theme: data.theme,
      };

      if (data.media) {
        state.media = data.media;
      }

      state.loading = false;
      state.ready = true;

      watchChanges(state, 'form');
    }

    /**
     * Save form data.
     */
    async function save(close = true, submittedBy = 'save-and-back') {
      state.submittedBy = submittedBy;
      state.loading = true;
      state.errors = {};

      try {
        await updateWebsite(project_id, website_id, state.form);
        await reloadWebsite(project_id, website_id);
        await reloadPage(project_id, page_id);

        root.$notify({ type: 'success', title: root.$tuf('success'), text: 'Website instellingen opgeslagen' });

        state.loading = false;

        commitChanges(state, 'form');

        if (close) {
          refs.sidebar.close();
        }
      } catch (error) {
        state.loading = false;
        state.errors = error.response.data.errors;
      }
    }

    onMounted(async () => {
      load();
    });

    function getMediaCollection(field) {
      return state.media.filter(file => file.id === field[0]);
    }

    function onMediaChanged(field, evt) {
      state.form.theme_options.logo[field] = evt;
    }

    return {
      ...toRefs(state),
      onMediaChanged,
      getMediaCollection,
      website_id,
      save,
    };
  },
};
</script>
